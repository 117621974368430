import __request__ from 'routing/request';
interface anyMap {
    readonly [_key: string]: unknown;
}
interface TelemetryUserSettings {
    readonly telemetry_permission_asked: boolean;
    readonly telemetry_enabled: boolean;
}
/**
 * Get telemetry information.
 */
export function get(): Promise<anyMap> {
    return __request__('GET', '/telemetry', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve a user's telemetry settings.
 */
export function getTelemetryUserSettings(): Promise<TelemetryUserSettings> {
    return __request__('GET', '/telemetry/user/settings', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a user's telemetry settings.
 * @param JSONbody The telemetry settings to assign to the user.
 */
export function saveTelemetryUserSettings(JSONbody: TelemetryUserSettings): Promise<void> {
    return __request__('PUT', '/telemetry/user/settings', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
