import __request__ from 'routing/request';
interface SingleSubsystemSummary {
    readonly level_syslog: number;
    readonly level: string;
    readonly description: string;
    readonly categories: string[];
    readonly title: string;
}
interface CallResult {
    readonly response: void;
    readonly server_error_message: string;
    readonly call_executed: boolean;
}
interface SubsystemSummaryMap {
    readonly [_key: string]: SubsystemSummary;
}
interface LoggersSummaryMap {
    readonly [_key: string]: LoggersSummary;
}
interface SubsystemSummary {
    readonly subsystems: {
        readonly [_key: string]: SingleSubsystemSummary;
    };
}
interface SingleLoggerSummary {
    readonly level_syslog: number;
    readonly level: string;
}
interface LoggersSummary {
    readonly total: number;
    readonly loggers: {
        readonly [_key: string]: SingleLoggerSummary;
    };
}
interface CallResultMap {
    readonly [_key: string]: CallResult;
}
/**
 * List all loggers of all nodes and their current levels
 */
export function loggers(): Promise<LoggersSummaryMap> {
    return __request__('GET', '/cluster/system/loggers', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List all logger subsystems and their current levels
 */
export function subsystems(): Promise<SubsystemSummaryMap> {
    return __request__('GET', '/cluster/system/loggers/subsystems', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Set the loglevel of a single logger
 */
export function setClusterSingleLoggerLevel(loggerName: string, level: string): Promise<CallResultMap> {
    return __request__('PUT', `/cluster/system/loggers/${loggerName}/level/${level}`, null, {}, {
        'Accept': []
    });
}
/**
 * Set the loglevel of a whole subsystem
 */
export function setSubsystemLoggerLevel(nodeId: string, subsystem: string, level: string): Promise<void> {
    return __request__('PUT', `/cluster/system/loggers/${nodeId}/subsystems/${subsystem}/level/${level}`, null, {}, {
        'Accept': []
    });
}
