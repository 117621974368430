// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jcr2Blo1owtKhor0NDK4 {\n    display: inline;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/common/EditableTitle.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","sourcesContent":[":local(.inlineForm) {\n    display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineForm": "jcr2Blo1owtKhor0NDK4"
};
export default ___CSS_LOADER_EXPORT___;
