import __request__ from 'routing/request';
interface MetricNamesResponse {
    readonly names: string[];
}
interface MetricsSummaryResponse {
    readonly total: number;
    readonly metrics: {
        readonly [_key: string]: {};
    }[];
}
interface MetricsReadRequest {
    readonly metrics: string[];
}
/**
 * Get the values of multiple metrics at once from node
 * @param nodeId The id of the node whose metrics we want.
 */
export function multipleMetrics(nodeId: string, Requestedmetrics: MetricsReadRequest): Promise<MetricsSummaryResponse> {
    return __request__('POST', `/cluster/${nodeId}/metrics/multiple`, Requestedmetrics, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all metrics keys/names from node
 * @param nodeId The id of the node whose metrics we want.
 */
export function metricNames(nodeId: string): Promise<MetricNamesResponse> {
    return __request__('GET', `/cluster/${nodeId}/metrics/names`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all metrics of a namespace from node
 * @param nodeId The id of the node whose metrics we want.
 */
export function byNamespace(nodeId: string, namespace: string): Promise<MetricsSummaryResponse> {
    return __request__('GET', `/cluster/${nodeId}/metrics/namespace/${namespace}`, null, {}, {
        'Accept': ['application/json']
    });
}
