import __request__ from 'routing/request';
interface LookupCacheConfiguration {
    readonly type: string;
}
interface LookupTableApi {
    readonly cache_id: string;
    readonly default_multi_value_type: 'STRING' | 'NUMBER' | 'OBJECT' | 'BOOLEAN' | 'NULL';
    readonly name: string;
    readonly description: string;
    readonly _scope: string;
    readonly id: string;
    readonly title: string;
    readonly content_pack: string;
    readonly data_adapter_id: string;
    readonly default_single_value_type: 'STRING' | 'NUMBER' | 'OBJECT' | 'BOOLEAN' | 'NULL';
    readonly default_single_value: string;
    readonly default_multi_value: string;
}
interface ErrorStates {
    readonly tables: {
        readonly [_key: string]: string;
    };
    readonly caches: {
        readonly [_key: string]: string;
    };
    readonly data_adapters: {
        readonly [_key: string]: string;
    };
}
interface ValidationResult {
    readonly error_context: {
        readonly [_key: string]: string[];
    };
    readonly failed: boolean;
    readonly errors: {
        readonly [_key: string]: string[];
    };
}
interface ErrorStatesRequest {
    readonly tables: string[];
    readonly caches: string[];
    readonly data_adapters: string[];
}
interface DataAdapterApi {
    readonly custom_error_ttl: number;
    readonly name: string;
    readonly description: string;
    readonly _scope: string;
    readonly id: string;
    readonly title: string;
    readonly config: LookupDataAdapterConfiguration;
    readonly content_pack: string;
    readonly custom_error_ttl_unit: 'NANOSECONDS' | 'MICROSECONDS' | 'MILLISECONDS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS';
    readonly custom_error_ttl_enabled: boolean;
}
interface LookupTablePage {
    readonly lookup_tables: LookupTableApi[];
    readonly per_page: number;
    readonly total: number;
    readonly caches: {
        readonly [_key: string]: CacheApi;
    };
    readonly query: string;
    readonly count: number;
    readonly data_adapters: {
        readonly [_key: string]: DataAdapterApi;
    };
    readonly page: number;
}
interface LookupDataAdapterConfiguration {
    readonly type: string;
}
interface DescriptorMap {
    readonly [_key: string]: Descriptor;
}
interface CachesPage {
    readonly per_page: number;
    readonly total: number;
    readonly caches: CacheApi[];
    readonly query: string;
    readonly count: number;
    readonly page: number;
}
interface LookupResult {
    readonly multi_value: {
        readonly [_key: string]: Object;
    };
    readonly single_value: Object;
    readonly string_list_value: string[];
    readonly ttl: number;
    readonly has_error: boolean;
}
interface DataAdapterPage {
    readonly per_page: number;
    readonly total: number;
    readonly query: string;
    readonly count: number;
    readonly data_adapters: DataAdapterApi[];
    readonly page: number;
}
interface Descriptor {
    readonly config_class: string;
    readonly default_config: LookupCacheConfiguration;
    readonly type: string;
}
interface Object {
}
interface CacheApi {
    readonly name: string;
    readonly description: string;
    readonly _scope: string;
    readonly id: string;
    readonly title: string;
    readonly config: LookupCacheConfiguration;
    readonly content_pack: string;
}
/**
 * List available data adapters
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function adapters(sort: 'title' | 'description' | 'name' | 'id' = 'title', page: number = 1, per_page: number = 50, order: 'asc' | ' desc' | 'desc' = 'desc', query?: string): Promise<DataAdapterPage> {
    return __request__('GET', '/system/lookup/adapters', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new data adapter
 */
export function createAdapter(arg0?: DataAdapterApi): Promise<DataAdapterApi> {
    return __request__('POST', '/system/lookup/adapters', arg0, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Validate the data adapter config
 */
export function validateAdapter(arg0?: DataAdapterApi): Promise<ValidationResult> {
    return __request__('POST', '/system/lookup/adapters/validate', arg0, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List the given data adapter
 */
export function getAdapter(idOrName?: string): Promise<DataAdapterApi> {
    return __request__('GET', `/system/lookup/adapters/${idOrName}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete the given data adapter
 */
export function deleteAdapter(idOrName?: string): Promise<DataAdapterApi> {
    return __request__('DELETE', `/system/lookup/adapters/${idOrName}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update the given data adapter settings
 */
export function updateAdapter(idOrName?: string, arg1?: DataAdapterApi): Promise<DataAdapterApi> {
    return __request__('PUT', `/system/lookup/adapters/${idOrName}`, arg1, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Query a lookup table
 */
export function performAdapterLookup(name?: string, key?: string): Promise<LookupResult> {
    return __request__('GET', `/system/lookup/adapters/${name}/query`, null, { 'key': key }, {
        'Accept': ['application/json']
    });
}
/**
 * List available caches
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function caches(sort: 'title' | 'description' | 'name' | 'id' = 'title', page: number = 1, per_page: number = 50, order: 'asc' | ' desc' | 'desc' = 'desc', query?: string): Promise<CachesPage> {
    return __request__('GET', '/system/lookup/caches', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new cache
 */
export function createCache(arg0?: CacheApi): Promise<CacheApi> {
    return __request__('POST', '/system/lookup/caches', arg0, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Validate the cache config
 */
export function validateCache(arg0?: CacheApi): Promise<ValidationResult> {
    return __request__('POST', '/system/lookup/caches/validate', arg0, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List the given cache
 */
export function getCache(idOrName?: string): Promise<CacheApi> {
    return __request__('GET', `/system/lookup/caches/${idOrName}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete the given cache
 */
export function deleteCache(idOrName?: string): Promise<CacheApi> {
    return __request__('DELETE', `/system/lookup/caches/${idOrName}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update the given cache settings
 */
export function updateCache(idOrName?: string, arg1?: CacheApi): Promise<CacheApi> {
    return __request__('PUT', `/system/lookup/caches/${idOrName}`, arg1, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve the runtime error states of the given lookup tables, caches and adapters
 */
export function errorStates(request?: ErrorStatesRequest): Promise<ErrorStates> {
    return __request__('POST', '/system/lookup/errorstates', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List configured lookup tables
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function tables(sort: 'title' | 'description' | 'name' | 'id' = 'title', page: number = 1, per_page: number = 50, order: 'asc' | ' desc' | 'desc' = 'desc', query?: string, resolve: boolean = false): Promise<LookupTablePage> {
    return __request__('GET', '/system/lookup/tables', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query, 'resolve': resolve }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new lookup table
 */
export function createTable(arg0?: LookupTableApi): Promise<LookupTableApi> {
    return __request__('POST', '/system/lookup/tables', arg0, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Validate the lookup table config
 */
export function validateTable(arg0?: LookupTableApi): Promise<ValidationResult> {
    return __request__('POST', '/system/lookup/tables/validate', arg0, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve the named lookup table
 */
export function get(idOrName?: string, resolve: boolean = false): Promise<LookupTablePage> {
    return __request__('GET', `/system/lookup/tables/${idOrName}`, null, { 'resolve': resolve }, {
        'Accept': ['application/json']
    });
}
/**
 * Update the given lookup table
 */
export function updateTable(idOrName?: string, arg1?: LookupTableApi): Promise<LookupTableApi> {
    return __request__('PUT', `/system/lookup/tables/${idOrName}`, arg1, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete the lookup table
 */
export function removeTable(idOrName?: string): Promise<LookupTableApi> {
    return __request__('DELETE', `/system/lookup/tables/${idOrName}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Purge lookup table cache
 */
export function performPurge(idOrName?: string, key?: string): Promise<void> {
    return __request__('POST', `/system/lookup/tables/${idOrName}/purge`, null, { 'key': key }, {
        'Accept': ['application/json']
    });
}
/**
 * Query a lookup table
 */
export function performLookup(name?: string, key?: string): Promise<LookupResult> {
    return __request__('GET', `/system/lookup/tables/${name}/query`, null, { 'key': key }, {
        'Accept': ['application/json']
    });
}
/**
 * List available data adapter types
 */
export function availableAdapterTypes(): Promise<DescriptorMap> {
    return __request__('GET', '/system/lookup/types/adapters', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List available caches types
 */
export function availableCacheTypes(): Promise<DescriptorMap> {
    return __request__('GET', '/system/lookup/types/caches', null, {}, {
        'Accept': ['application/json']
    });
}
