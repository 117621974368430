import __request__ from 'routing/request';
interface Object {
}
interface TemplateRenderResponse {
    readonly description: string;
    readonly title: string;
}
interface TemplateRenderRequest {
    readonly values: {
        readonly [_key: string]: Object;
    };
}
/**
 * Get HTML formatted message
 */
export function renderHtml(type: 'DEFLECTOR_EXISTS_AS_INDEX' | 'MULTI_MASTER' | 'NO_MASTER' | 'ES_OPEN_FILES' | 'ES_CLUSTER_RED' | 'ES_UNAVAILABLE' | 'NO_INPUT_RUNNING' | 'INPUT_FAILED_TO_START' | 'INPUT_FAILING' | 'INPUT_FAILURE_SHUTDOWN' | 'CHECK_SERVER_CLOCKS' | 'OUTDATED_VERSION' | 'EMAIL_TRANSPORT_CONFIGURATION_INVALID' | 'EMAIL_TRANSPORT_FAILED' | 'STREAM_PROCESSING_DISABLED' | 'GC_TOO_LONG' | 'JOURNAL_UTILIZATION_TOO_HIGH' | 'JOURNAL_UNCOMMITTED_MESSAGES_DELETED' | 'OUTPUT_DISABLED' | 'OUTPUT_FAILING' | 'INDEX_RANGES_RECALCULATION' | 'GENERIC' | 'ES_INDEX_BLOCKED' | 'ES_NODE_DISK_WATERMARK_LOW' | 'ES_NODE_DISK_WATERMARK_HIGH' | 'ES_NODE_DISK_WATERMARK_FLOOD_STAGE' | 'ES_VERSION_MISMATCH' | 'LEGACY_LDAP_CONFIG_MIGRATION' | 'MULTI_LEADER' | 'NO_LEADER' | 'ARCHIVING_SUMMARY' | 'SEARCH_ERROR', JSONbody?: TemplateRenderRequest): Promise<TemplateRenderResponse> {
    return __request__('POST', `/system/notification/message/html/${type}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get HTML formatted message
 */
export function renderHtmlWithKey(type: 'DEFLECTOR_EXISTS_AS_INDEX' | 'MULTI_MASTER' | 'NO_MASTER' | 'ES_OPEN_FILES' | 'ES_CLUSTER_RED' | 'ES_UNAVAILABLE' | 'NO_INPUT_RUNNING' | 'INPUT_FAILED_TO_START' | 'INPUT_FAILING' | 'INPUT_FAILURE_SHUTDOWN' | 'CHECK_SERVER_CLOCKS' | 'OUTDATED_VERSION' | 'EMAIL_TRANSPORT_CONFIGURATION_INVALID' | 'EMAIL_TRANSPORT_FAILED' | 'STREAM_PROCESSING_DISABLED' | 'GC_TOO_LONG' | 'JOURNAL_UTILIZATION_TOO_HIGH' | 'JOURNAL_UNCOMMITTED_MESSAGES_DELETED' | 'OUTPUT_DISABLED' | 'OUTPUT_FAILING' | 'INDEX_RANGES_RECALCULATION' | 'GENERIC' | 'ES_INDEX_BLOCKED' | 'ES_NODE_DISK_WATERMARK_LOW' | 'ES_NODE_DISK_WATERMARK_HIGH' | 'ES_NODE_DISK_WATERMARK_FLOOD_STAGE' | 'ES_VERSION_MISMATCH' | 'LEGACY_LDAP_CONFIG_MIGRATION' | 'MULTI_LEADER' | 'NO_LEADER' | 'ARCHIVING_SUMMARY' | 'SEARCH_ERROR', key: string, JSONbody?: TemplateRenderRequest): Promise<TemplateRenderResponse> {
    return __request__('POST', `/system/notification/message/html/${type}/${key}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get plaintext formatted message
 */
export function renderPlainText(type: 'DEFLECTOR_EXISTS_AS_INDEX' | 'MULTI_MASTER' | 'NO_MASTER' | 'ES_OPEN_FILES' | 'ES_CLUSTER_RED' | 'ES_UNAVAILABLE' | 'NO_INPUT_RUNNING' | 'INPUT_FAILED_TO_START' | 'INPUT_FAILING' | 'INPUT_FAILURE_SHUTDOWN' | 'CHECK_SERVER_CLOCKS' | 'OUTDATED_VERSION' | 'EMAIL_TRANSPORT_CONFIGURATION_INVALID' | 'EMAIL_TRANSPORT_FAILED' | 'STREAM_PROCESSING_DISABLED' | 'GC_TOO_LONG' | 'JOURNAL_UTILIZATION_TOO_HIGH' | 'JOURNAL_UNCOMMITTED_MESSAGES_DELETED' | 'OUTPUT_DISABLED' | 'OUTPUT_FAILING' | 'INDEX_RANGES_RECALCULATION' | 'GENERIC' | 'ES_INDEX_BLOCKED' | 'ES_NODE_DISK_WATERMARK_LOW' | 'ES_NODE_DISK_WATERMARK_HIGH' | 'ES_NODE_DISK_WATERMARK_FLOOD_STAGE' | 'ES_VERSION_MISMATCH' | 'LEGACY_LDAP_CONFIG_MIGRATION' | 'MULTI_LEADER' | 'NO_LEADER' | 'ARCHIVING_SUMMARY' | 'SEARCH_ERROR', JSONbody?: TemplateRenderRequest): Promise<TemplateRenderResponse> {
    return __request__('POST', `/system/notification/message/plaintext/${type}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get plaintext formatted message
 */
export function renderPlainTextWithKey(type: 'DEFLECTOR_EXISTS_AS_INDEX' | 'MULTI_MASTER' | 'NO_MASTER' | 'ES_OPEN_FILES' | 'ES_CLUSTER_RED' | 'ES_UNAVAILABLE' | 'NO_INPUT_RUNNING' | 'INPUT_FAILED_TO_START' | 'INPUT_FAILING' | 'INPUT_FAILURE_SHUTDOWN' | 'CHECK_SERVER_CLOCKS' | 'OUTDATED_VERSION' | 'EMAIL_TRANSPORT_CONFIGURATION_INVALID' | 'EMAIL_TRANSPORT_FAILED' | 'STREAM_PROCESSING_DISABLED' | 'GC_TOO_LONG' | 'JOURNAL_UTILIZATION_TOO_HIGH' | 'JOURNAL_UNCOMMITTED_MESSAGES_DELETED' | 'OUTPUT_DISABLED' | 'OUTPUT_FAILING' | 'INDEX_RANGES_RECALCULATION' | 'GENERIC' | 'ES_INDEX_BLOCKED' | 'ES_NODE_DISK_WATERMARK_LOW' | 'ES_NODE_DISK_WATERMARK_HIGH' | 'ES_NODE_DISK_WATERMARK_FLOOD_STAGE' | 'ES_VERSION_MISMATCH' | 'LEGACY_LDAP_CONFIG_MIGRATION' | 'MULTI_LEADER' | 'NO_LEADER' | 'ARCHIVING_SUMMARY' | 'SEARCH_ERROR', key: string, JSONbody?: TemplateRenderRequest): Promise<TemplateRenderResponse> {
    return __request__('POST', `/system/notification/message/plaintext/${type}/${key}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
