import __request__ from 'routing/request';
type stringArray = string[];
interface ReaderPermissionResponse {
    readonly permissions: string[];
}
interface stringArrayMapMap {
    readonly [_key: string]: stringArrayMap;
}
interface stringArrayMap {
    readonly [_key: string]: stringArray;
}
/**
 * Get all available user permissions.
 */
export function permissions(): Promise<stringArrayMapMap> {
    return __request__('GET', '/system/permissions', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the initial permissions assigned to a reader account
 */
export function readerPermissions(username: string): Promise<ReaderPermissionResponse> {
    return __request__('GET', `/system/permissions/reader/${username}`, null, {}, {
        'Accept': ['application/json']
    });
}
