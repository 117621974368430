import __request__ from 'routing/request';
interface OpenIndicesInfo {
    readonly indices: IndexInfo[];
}
type IndexInfoArray = IndexInfo[];
interface AllIndices {
    readonly all: OpenIndicesInfo;
    readonly reopened: ClosedIndices;
    readonly closed: ClosedIndices;
}
interface DocsStats {
    readonly deleted: number;
    readonly count: number;
}
interface IndexInfo {
    readonly routing: ShardRouting[];
    readonly reopened: boolean;
    readonly all_shards: IndexStats;
    readonly primary_shards: IndexStats;
    readonly index_name: string;
}
interface IndicesReadRequest {
    readonly indices: string[];
}
interface IndexStats {
    readonly store_size_bytes: number;
    readonly flush: TimeAndTotalStats;
    readonly documents: DocsStats;
    readonly get: TimeAndTotalStats;
    readonly merge: TimeAndTotalStats;
    readonly open_search_contexts: number;
    readonly index: TimeAndTotalStats;
    readonly refresh: TimeAndTotalStats;
    readonly search_fetch: TimeAndTotalStats;
    readonly search_query: TimeAndTotalStats;
    readonly segments: number;
}
interface ShardRouting {
    readonly relocating_to: string;
    readonly node_name: string;
    readonly active: boolean;
    readonly node_hostname: string;
    readonly id: number;
    readonly state: string;
    readonly primary: boolean;
    readonly node_id: string;
}
interface ClosedIndices {
    readonly indices: string[];
    readonly total: number;
}
interface TimeAndTotalStats {
    readonly total: number;
    readonly time_seconds: number;
}
/**
 * List all open, closed and reopened indices.
 */
export function all(): Promise<AllIndices> {
    return __request__('GET', '/system/indexer/indices', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a list of closed indices that can be reopened.
 */
export function closed(): Promise<ClosedIndices> {
    return __request__('GET', '/system/indexer/indices/closed', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get information of all specified indices and their shards.
 */
export function multiple(Requestedindices: IndicesReadRequest): Promise<IndexInfoArray> {
    return __request__('POST', '/system/indexer/indices/multiple', Requestedindices, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get information of all open indices managed by Graylog and their shards.
 */
export function open(): Promise<OpenIndicesInfo> {
    return __request__('GET', '/system/indexer/indices/open', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a list of reopened indices, which will not be cleaned by retention cleaning
 */
export function reopened(): Promise<ClosedIndices> {
    return __request__('GET', '/system/indexer/indices/reopened', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a list of closed indices that can be reopened.
 */
export function indexSetClosed(indexSetId?: string): Promise<ClosedIndices> {
    return __request__('GET', `/system/indexer/indices/${indexSetId}/closed`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List all open, closed and reopened indices.
 */
export function indexSetList(indexSetId?: string): Promise<AllIndices> {
    return __request__('GET', `/system/indexer/indices/${indexSetId}/list`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get information of all open indices managed by Graylog and their shards.
 */
export function indexSetOpen(indexSetId?: string): Promise<OpenIndicesInfo> {
    return __request__('GET', `/system/indexer/indices/${indexSetId}/open`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a list of reopened indices, which will not be cleaned by retention cleaning
 */
export function indexSetReopened(indexSetId?: string): Promise<ClosedIndices> {
    return __request__('GET', `/system/indexer/indices/${indexSetId}/reopened`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get information of an index and its shards.
 */
export function single(index?: string): Promise<IndexInfo> {
    return __request__('GET', `/system/indexer/indices/${index}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an index. This will also trigger an index ranges rebuild job.
 */
export function remove(index?: string): Promise<void> {
    return __request__('DELETE', `/system/indexer/indices/${index}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Close an index. This will also trigger an index ranges rebuild job.
 */
export function close(index?: string): Promise<void> {
    return __request__('POST', `/system/indexer/indices/${index}/close`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Reopen a closed index. This will also trigger an index ranges rebuild job.
 */
export function reopen(index?: string): Promise<void> {
    return __request__('POST', `/system/indexer/indices/${index}/reopen`, null, {}, {
        'Accept': ['application/json']
    });
}
