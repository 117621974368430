// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qWBqb_23sgqkD946642Z {\n    position: absolute;\n    z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n.VEeyO9k8vNGGhxOuqVkQ .leaflet-control-container {\n    display: none;\n}\n\n.leaflet-top, .leaflet-bottom {\n    z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n@media print {\n    .KoTenzOWhTf1f7jkioVR {\n        margin: 0 auto;\n        page-break-inside: avoid;\n    }\n\n    /* Hide zoom controls */\n    .leaflet-control-container {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://./src/views/components/visualizations/worldmap/MapVisualization.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY,EAAE,6CAA6C;AAC/D;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY,EAAE,6CAA6C;AAC/D;;AAEA;IACI;QACI,cAAc;QACd,wBAAwB;IAC5B;;IAEA,uBAAuB;IACvB;QACI,aAAa;IACjB;AACJ","sourcesContent":[":local(.overlay) {\n    position: absolute;\n    z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n:local(.mapLocked) .leaflet-control-container {\n    display: none;\n}\n\n.leaflet-top, .leaflet-bottom {\n    z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n@media print {\n    :local(.map) {\n        margin: 0 auto;\n        page-break-inside: avoid;\n    }\n\n    /* Hide zoom controls */\n    .leaflet-control-container {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "qWBqb_23sgqkD946642Z",
	"mapLocked": "VEeyO9k8vNGGhxOuqVkQ",
	"map": "KoTenzOWhTf1f7jkioVR"
};
export default ___CSS_LOADER_EXPORT___;
