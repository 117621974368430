import __request__ from 'routing/request';
interface ExtractorCreated {
    readonly extractor_id: string;
}
interface ExtractorMetrics {
    readonly condition_misses: number;
    readonly execution: TimerRateMetricsResponse;
    readonly total: TimerRateMetricsResponse;
    readonly condition: TimerRateMetricsResponse;
    readonly condition_hits: number;
    readonly converters: TimerRateMetricsResponse;
}
interface ExtractorSummaryList {
    readonly total: number;
    readonly extractors: ExtractorSummary[];
}
interface RateMetricsResponse {
    readonly total: number;
    readonly mean: number;
    readonly five_minute: number;
    readonly fifteen_minute: number;
    readonly one_minute: number;
}
interface OrderExtractorsRequest {
    readonly order: {
        readonly [_key: string]: string;
    };
}
interface Object {
}
interface TimerRateMetricsResponse {
    readonly rate: RateMetricsResponse;
    readonly rate_unit: string;
    readonly time: TimerMetricsResponse;
    readonly duration_unit: string;
}
interface ExtractorSummary {
    readonly creator_user_id: string;
    readonly source_field: string;
    readonly condition_type: string;
    readonly converter_exceptions: number;
    readonly title: string;
    readonly type: string;
    readonly cursor_strategy: string;
    readonly exceptions: number;
    readonly target_field: string;
    readonly extractor_config: {
        readonly [_key: string]: Object;
    };
    readonly condition_value: string;
    readonly converters: {
        readonly [_key: string]: Object;
    }[];
    readonly id: string;
    readonly metrics: ExtractorMetrics;
    readonly order: number;
}
interface CreateExtractorRequest {
    readonly source_field: string;
    readonly target_field: string;
    readonly extractor_config: {
        readonly [_key: string]: Object;
    };
    readonly extractor_type: string;
    readonly condition_type: string;
    readonly condition_value: string;
    readonly converters: {
        readonly [_key: string]: Object;
    }[];
    readonly title: string;
    readonly cursor_strategy: string;
    readonly order: number;
}
interface TimerMetricsResponse {
    readonly min: number;
    readonly max: number;
    readonly std_dev: number;
    readonly mean: number;
    readonly '95th_percentile': number;
    readonly '99th_percentile': number;
    readonly '98th_percentile': number;
}
/**
 * List all extractors of an input
 */
export function list(inputId: string): Promise<ExtractorSummaryList> {
    return __request__('GET', `/system/inputs/${inputId}/extractors`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add an extractor to an input
 */
export function create(inputId: string, JSONbody: CreateExtractorRequest): Promise<ExtractorCreated> {
    return __request__('POST', `/system/inputs/${inputId}/extractors`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update extractor order of an input
 * @param inputId Persist ID (!) of input.
 */
export function order(inputId: string, JSONbody: OrderExtractorsRequest): Promise<void> {
    return __request__('POST', `/system/inputs/${inputId}/extractors/order`, JSONbody, {}, {
        'Accept': []
    });
}
/**
 * Update an extractor
 */
export function update(inputId: string, extractorId: string, JSONbody: CreateExtractorRequest): Promise<ExtractorSummary> {
    return __request__('PUT', `/system/inputs/${inputId}/extractors/${extractorId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get information of a single extractor of an input
 */
export function single(inputId: string, extractorId: string): Promise<ExtractorSummary> {
    return __request__('GET', `/system/inputs/${inputId}/extractors/${extractorId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an extractor
 */
export function terminate(inputId: string, extractorId: string): Promise<void> {
    return __request__('DELETE', `/system/inputs/${inputId}/extractors/${extractorId}`, null, {}, {
        'Accept': ['application/json']
    });
}
