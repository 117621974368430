import __request__ from 'routing/request';
interface RetentionStrategyConfig {
    readonly max_number_of_indices: number;
    readonly type: string;
}
interface RotationStrategyConfig {
    readonly type: string;
}
interface IndexSetStats {
    readonly indices: number;
    readonly size: number;
    readonly documents: number;
}
interface IndexSetSummary {
    readonly index_optimization_max_num_segments: number;
    readonly replicas: number;
    readonly field_type_refresh_interval: string;
    readonly description: string;
    readonly index_optimization_disabled: boolean;
    readonly can_be_default: boolean;
    readonly creation_date: string;
    readonly title: string;
    readonly retention_strategy_class: string;
    readonly retention_strategy: RetentionStrategyConfig;
    readonly writable: boolean;
    readonly shards: number;
    readonly default: boolean;
    readonly index_analyzer: string;
    readonly rotation_strategy: RotationStrategyConfig;
    readonly index_template_type: string;
    readonly id: string;
    readonly rotation_strategy_class: string;
    readonly index_prefix: string;
}
interface IndexSetResponse {
    readonly total: number;
    readonly stats: {
        readonly [_key: string]: IndexSetStats;
    };
    readonly index_sets: IndexSetSummary[];
}
interface IndexSetUpdateRequest {
    readonly shards: number;
    readonly index_optimization_max_num_segments: number;
    readonly replicas: number;
    readonly rotation_strategy: RotationStrategyConfig;
    readonly field_type_refresh_interval: string;
    readonly description: string;
    readonly index_optimization_disabled: boolean;
    readonly rotation_strategy_class: string;
    readonly title: string;
    readonly retention_strategy_class: string;
    readonly retention_strategy: RetentionStrategyConfig;
    readonly writable: boolean;
}
/**
 * Get a list of all index sets
 * @param skip The number of elements to skip (offset).
 * @param limit The maximum number of elements to return.
 * @param stats Include index set stats.
 */
export function list(skip: number = 0, limit: number = 0, stats: boolean = false): Promise<IndexSetResponse> {
    return __request__('GET', '/system/indices/index_sets', null, { 'skip': skip, 'limit': limit, 'stats': stats }, {
        'Accept': ['application/json']
    });
}
/**
 * Create index set
 */
export function save(Indexsetconfiguration: IndexSetSummary): Promise<IndexSetSummary> {
    return __request__('POST', '/system/indices/index_sets', Indexsetconfiguration, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get stats of all index sets
 */
export function globalStats(): Promise<IndexSetStats> {
    return __request__('GET', '/system/indices/index_sets/stats', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get index set
 */
export function get(id: string): Promise<IndexSetSummary> {
    return __request__('GET', `/system/indices/index_sets/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update index set
 */
export function update(id: string, Indexsetconfiguration: IndexSetUpdateRequest): Promise<IndexSetSummary> {
    return __request__('PUT', `/system/indices/index_sets/${id}`, Indexsetconfiguration, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete index set
 */
export function remove(id: string, delete_indices: boolean = true): Promise<void> {
    return __request__('DELETE', `/system/indices/index_sets/${id}`, null, { 'delete_indices': delete_indices }, {
        'Accept': ['application/json']
    });
}
/**
 * Set default index set
 */
export function setDefault(id: string): Promise<IndexSetSummary> {
    return __request__('PUT', `/system/indices/index_sets/${id}/default`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get index set statistics
 */
export function indexSetStatistics(id: string): Promise<IndexSetStats> {
    return __request__('GET', `/system/indices/index_sets/${id}/stats`, null, {}, {
        'Accept': ['application/json']
    });
}
