import __request__ from 'routing/request';
interface PaginatedResponse {
    readonly [_key: string]: Object;
}
interface PaginationParameters {
    readonly per_page: number;
    readonly query: string;
    readonly page: number;
    readonly sort_by: string;
    readonly order: string;
}
interface AuthServiceBackendConfig {
    readonly type: string;
}
interface AuthServiceBackendDTO {
    readonly default_roles: string[];
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly config: AuthServiceBackendConfig;
}
interface Object {
}
/**
 * Returns available authentication service backends
 */
export function list(paginationparameters?: PaginationParameters): Promise<PaginatedResponse> {
    return __request__('GET', '/system/authentication/services/backends', paginationparameters, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Creates a new authentication service backend
 */
export function create(JSONbody: AuthServiceBackendDTO): Promise<unknown> {
    return __request__('POST', '/system/authentication/services/backends', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns type of currently active authentication service backend
 */
export function getActiveType(): Promise<unknown> {
    return __request__('GET', '/system/authentication/services/backends/active-backend/type', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns the authentication service backend for the given ID
 */
export function get(backendId: string): Promise<unknown> {
    return __request__('GET', `/system/authentication/services/backends/${backendId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Updates an existing authentication service backend
 */
export function update(backendId: string, JSONbody: AuthServiceBackendDTO): Promise<unknown> {
    return __request__('PUT', `/system/authentication/services/backends/${backendId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete authentication service backend
 */
export function remove(backendId: string): Promise<void> {
    return __request__('DELETE', `/system/authentication/services/backends/${backendId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get paginated users for an authentication service backend
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getUsers(sort: 'username' | 'full_name' | 'email' = 'full_name', backendId: string, page: number = 1, per_page: number = 50, query?: string, order: 'asc' | ' desc' = 'asc'): Promise<PaginatedResponse> {
    return __request__('GET', `/system/authentication/services/backends/${backendId}/users`, null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
