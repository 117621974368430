import __request__ from 'routing/request';
interface WhitelistCheckResponse {
    readonly is_whitelisted: boolean;
    readonly url: string;
}
interface WhitelistRegexGenerationResponse {
    readonly regex: string;
}
interface WhitelistEntry {
    readonly id: string;
    readonly type: 'literal' | 'regex';
    readonly title: string;
    readonly value: string;
}
interface UrlWhitelist {
    readonly entries: WhitelistEntry[];
    readonly disabled: boolean;
}
interface WhitelistRegexGenerationRequest {
    readonly url_template: string;
    readonly placeholder: string;
}
interface WhitelistCheckRequest {
    readonly url: string;
}
/**
 * Get url whitelist.
 */
export function get(): Promise<UrlWhitelist> {
    return __request__('GET', '/system/urlwhitelist', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update url whitelist.
 */
export function put(whitelist: UrlWhitelist): Promise<unknown> {
    return __request__('PUT', '/system/urlwhitelist', whitelist, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Check if a url is whitelisted.
 */
export function check(JSONbody: WhitelistCheckRequest): Promise<WhitelistCheckResponse> {
    return __request__('POST', '/system/urlwhitelist/check', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Generates a regex that can be used as a value for a whitelist entry.
 */
export function generateRegex(JSONbody: WhitelistRegexGenerationRequest): Promise<WhitelistRegexGenerationResponse> {
    return __request__('POST', '/system/urlwhitelist/generate_regex', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
