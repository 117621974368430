import __request__ from 'routing/request';
interface MessageProcessorsConfigWithDescriptors {
    readonly disabled_processors: string[];
    readonly processor_order: MessageProcessorDescriptor[];
}
interface MessageProcessorDescriptor {
    readonly name: string;
    readonly class_name: string;
}
/**
 * Get message processor configuration
 */
export function config(): Promise<MessageProcessorsConfigWithDescriptors> {
    return __request__('GET', '/system/messageprocessors/config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update message processor configuration
 */
export function updateConfig(config: MessageProcessorsConfigWithDescriptors): Promise<MessageProcessorsConfigWithDescriptors> {
    return __request__('PUT', '/system/messageprocessors/config', config, {}, {
        'Accept': ['application/json']
    });
}
